import React, { useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import {
  Box,
  Drawer,
  Toolbar,
  List,
  IconButton,
  Button,
  Stack,
  Backdrop,
  useMediaQuery,
  Typography,
} from "@mui/material"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import { styled, useTheme } from "@mui/material/styles"
import { colors, Footer, Dialog } from "@parallel-domain/pd-theme"
import type { NavItem } from "@parallel-domain/pd-theme/types"

import {
  Compass as ExploreIcon,
  GoogleDocs as DatasetsIcon,
  OpenBook as DocumentationIcon,
  Settings as SettingsIcon,
  Flask as DataLabIcon,
  Menu as MenuIcon,
  HeadsetHelp as SupportIcon,
} from "iconoir-react"

// import app components
import UserAvatar from "./UserAvatar"
import Logo from "assets/icons/pd_logo.svg"
import useLayout from "../../store/useLayout"
import useCustomSession from "features/authentication/hooks/useSession"
import ReleaseToggle from "features/releases/components/ReleaseToggle"
import SidebarMenu from "./SidebarMenu"
import Search from "./Search"
import useReleases from "features/releases/store/useReleases"
import * as RELEASE_CONSTANTS from "features/releases/constants"

const drawerWidth = 240

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  maxWidth: "100vw",
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.up("lg")]: {
    marginLeft: `-${drawerWidth}px`, // Apply marginLeft only for lg and above
    ...(open && {
      marginLeft: 0, // Reset marginLeft to 0 when open
    }),
  },
}))

type AppBarProps = MuiAppBarProps & {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: 1190,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [theme.breakpoints.up("lg")]: {
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
    }),
  },
}))

export type Nav = {
  isVisible?: boolean
  children: React.ReactNode | React.ReactNode[]
}

const Nav = (props: Nav) => {
  const { isVisible = true, children } = props

  const [warning, setWarning] = useState(false)

  const activeRelease = useReleases((state) => state.activeRelease)

  const { data: session } = useCustomSession()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

  const router = useRouter()

  const menu = useLayout((state) => state.menu)
  const setMenu = useLayout((state) => state.setMenu)

  // Toggle menu when switching resizing window
  useEffect(() => {
    setMenu(!isMobile)
  }, [isMobile])

  // Hide sidebar on mobile on page change
  useEffect(() => {
    if (isMobile) {
      setMenu(false)
    }
  }, [isMobile, router.asPath])

  useEffect(() => {
    if (activeRelease.includes("-rc")) {
      setWarning(true)
    }
  }, [activeRelease])

  const items = useMemo(() => {
    const items: NavItem[] = []

    if (session?.user?.activeOrganization?.capabilities?.includes("step")) {
      items.push({
        label: "Data Lab",
        icon: <DataLabIcon />,
        href: "/data-lab/dashboard",
        active: router.asPath.startsWith("/data-lab"),
        children: [
          {
            label: "Dashboard",
            href: "/data-lab/dashboard",
            active: router.asPath.includes("/data-lab/dashboard"),
          },
          {
            label: "Cloud Instances",
            href: "/data-lab/cloud-instances",
            active: router.asPath.includes("/data-lab/cloud-instances"),
          },
          {
            label: "API Access",
            href: "/data-lab/api-access",
            active: router.asPath.includes("/data-lab/api-access"),
          },
          {
            label: "Status",
            href: "/data-lab/status",
            active: router.asPath.includes("/data-lab/status"),
          },
        ],
      })
    }

    items.push({
      label: "Datasets",
      icon: <DatasetsIcon />,
      href: "/datasets",
      active: router.asPath.includes("/datasets"),
    })

    if (session?.user?.activeOrganization) {
      items.push({
        label: "Documentation",
        icon: <DocumentationIcon />,
        href: `/api/content/redirect-to-docs?version=${encodeURIComponent(activeRelease)}`,
        target: "_blank",
        active: false,
      })
    }

    if (session?.user?.activeOrganization) {
      items.push({
        label: "Explore",
        icon: <ExploreIcon />,
        href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/locations`,
        active: router.asPath.includes("/explore"),
        children: [
          {
            label: "Procedural Locations",
            href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/locations`,
            active: router.asPath.includes("/locations"),
          },
          {
            label: "Assets",
            href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/assets`,
            active: router.asPath.includes("/assets"),
          },
          {
            label: "Lighting",
            href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/lighting`,
            active: router.asPath.includes("/lighting"),
          },
        ],
      })

      if (session?.user?.activeOrganization?.capabilities?.includes("replicas")) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        items[items.length - 1].children.unshift({
          label: "Replica Locations",
          href: `/explore/${activeRelease || RELEASE_CONSTANTS.SAMPLE_RELEASE_VERSION}/replicas`,
          active: router.asPath.includes("/replicas"),
        })
      }
    }

    if (
      session?.user?.activeOrganization?.capabilities?.includes("user_management") ||
      (session?.user?.activeOrganization?.capabilities?.includes("organization_settings") &&
        session?.user?.activeOrganization?.role === "admin")
    ) {
      const settings = {
        label: "Organization",
        icon: <SettingsIcon />,
        active: router.asPath.includes("/settings/user-management") || router.asPath.includes("/settings/organization"),
        children: [],
      } as NavItem & { children: NavItem[] }

      if (session?.user?.activeOrganization?.capabilities?.includes("user_management")) {
        settings.children.push({
          label: "Users",
          href: "/settings/user-management",
          active: router.asPath.includes("/settings/user-management"),
        })
      }

      if (
        session?.user?.activeOrganization?.capabilities?.includes("organization_settings") &&
        session?.user?.activeOrganization?.role === "admin"
      ) {
        settings.children.push({
          label: "Settings",
          href: "/settings/organization",
          active: router.asPath.includes("/settings/organization"),
        })
      }

      items.push(settings)
    }

    if (session?.user?.activeOrganization) {
      items.push({
        label: "Support Forum",
        icon: <SupportIcon />,
        onClick: () => window.open("/api/content/redirect-to-support-forum", "_blank"),
        active: false,
      })
    }

    return items
  }, [router.asPath, session?.user?.activeOrganization, activeRelease])

  if (!isVisible) {
    return <>{children}</>
  }

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" color="secondary" open={menu}>
          <Toolbar>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Stack spacing={2} direction="row" alignItems="center" sx={{ flex: 1 }}>
                <IconButton color="inherit" onClick={() => setMenu(!menu)} edge="start">
                  <MenuIcon width={18} height={18} style={{ color: colors.icons[700] }} />
                </IconButton>

                <Search />
              </Stack>

              <Stack spacing={3} direction="row" alignItems="center">
                {!session?.error && session?.user?.activeOrganization?.releases && (
                  <ReleaseToggle sx={{ display: { xs: "none", md: "flex" } }} />
                )}

                <UserAvatar />
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            position: { xs: "fixed", lg: "initial" },
            zIndex: { xs: 1200, lg: "initial" },
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              borderRadius: 0,
              borderRight: 1,
              borderColor: colors.border[800],
              backgroundColor: colors.secondary[900],
              boxSizing: "border-box",
            },
          }}
          color="secondary"
          variant="persistent"
          anchor="left"
          open={menu}
        >
          <Stack spacing={2} flex={1} justifyContent="space-between">
            <Stack spacing={2}>
              <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
                <Logo width={160} />
              </Toolbar>

              <List>
                <SidebarMenu items={items} />
              </List>

              {!session?.error && session?.user?.activeOrganization?.releases && (
                <Box sx={{ p: 2, display: { md: "none" } }}>
                  <ReleaseToggle />
                </Box>
              )}
            </Stack>

            <Footer
              linkComponent={Link}
              items={[
                { label: "Privacy Policy", url: "/privacy-policy" },
                { label: "Terms of use", url: "/terms-of-use" },
              ]}
            />
          </Stack>
        </Drawer>

        <Backdrop open={menu} sx={{ display: { lg: "none" }, zIndex: 1195 }} onClick={() => setMenu(!open)} />

        <Main open={menu}>
          <Toolbar />
          {children}
        </Main>
      </Box>

      <Dialog open={warning} title="Important" fullWidth maxWidth="sm">
        <Stack spacing={3}>
          <Typography>
            You&apos;ve selected a pre-release version of Data Lab.
            <br />
            Please be aware that website content for this version may be incomplete and revised at any time.
            Additionally, the behavior and performance of any Data Lab instances running this version has not been fully
            characterized and may be unpredictable.
          </Typography>
          <Typography>Access to this version may be withdrawn at any time.</Typography>
          <Button fullWidth variant="outlined" onClick={() => setWarning(false)}>
            I understand
          </Button>
        </Stack>
      </Dialog>
    </>
  )
}

export default Nav
